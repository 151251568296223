import React from "react"
import Layout from "../components/layout"

import {Link} from "gatsby"

import HeaderH1 from "../components/headerH1";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

export default function Pricing() {
  return (
    <>
      <GatsbySeo title="Pricing"
                 description={`We offer a free, a growth and an enterprise plan. 5000 MTR is always free per Month. Start for free and just upgrade when you have more than 5000 MTR.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="pricing">
        <section className="bg-l6-background py-20" id="pricing">
          <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
            <HeaderH1 headerText="Pricing"/>
            <div className="flex flex-col sm:flex-row justify-center pt-2">
              <p className="text-lg mx-auto">
                We try to keep our pricing as simple as possible.
              </p>
            </div>
            <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
              <div
                className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 z-10">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden ">
                  <div className="w-full p-8 text-3xl font-bold text-center">
                    Forever Free Plan
                  </div>
                  <div className="h-1 w-full bg-l5-primary-background my-0 py-0 rounded-t"/>

                </div>
                <div
                  className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden p-0">
                  <div className="w-full pt-6 text-4xl font-bold text-center italic">
                    <span className="mb-4 block">0€ / mo</span>
                    <span className="text-base block">up to 5.000€ MTR</span>
                    <span className="text-sm block">- every month</span>
                    <span className="text-base block mt-2 text-blue-400">No credit card required!</span>
                  </div>
                  <div className="flex items-center justify-center">
                    <Link to="//app.linkfive.io/sign-up"
                          className="mx-auto lg:mx-0 hover:underline bg-l5-primary-background text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                          state={{scheduleDemo: true}}>
                      Start for Free!
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 z-10 border-l border-r">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden ">
                  <div className="w-full p-8 text-3xl font-bold text-center">
                    Growth Plan
                  </div>
                  <div className="h-1 w-full bg-l5-primary-background my-0 py-0 rounded-t"/>

                </div>
                <div
                  className="flex-none bg-white rounded-b rounded-t-none overflow-hidden p-0">
                  <div className="w-full pt-6 text-4xl font-bold text-center italic">
                    <span className="mb-4 block">6€ / mo</span>
                    <span className="text-base block">per 1.000 MTR</span>
                    <span className="text-sm block">- 5.000€ MTR still free</span>
                    <span className="text-sm block mb-4 ">&nbsp;</span>
                  </div>
                  <div className="flex items-center justify-center">
                    <Link to="//app.linkfive.io/sign-up"
                          className="mx-auto lg:mx-0 hover:underline bg-l5-primary-background text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                          state={{scheduleDemo: true}}>
                      Sign Up!
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                <div
                  className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden">
                  <div className="p-8 text-3xl font-bold text-center border-b-4">
                    Enterprise
                  </div>

                </div>
                <div
                  className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden p-0">
                  <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                    get a quote
                  </div>
                  <div className="flex items-center justify-center">
                    <Link to="/contact"
                          className="mx-auto lg:mx-0 hover:underline bg-l5-primary-background text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                          state={{scheduleDemo: true}}>
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
            <div className="container mx-auto px-4 pt-12 pb-0 text-gray-800 max-w-6xl">
              <details className="mx-8 my-4">
                <summary
                  className="font-semibold focus:outline-none py-2 px-4 highlight cursor-pointer">
                  What is MTR?
                </summary>
                <span>
                                <p className="pl-9">
                                    MTR stands for Monthly Tracked Revenue and represents how much revenue the company earned per month.
                                </p>
                            </span>
              </details>
              <details className="mx-8 my-4">
                <summary
                  className="font-semibold focus:outline-none py-2 px-4 highlight cursor-pointer">
                  What is the difference between MTR and MRR?
                </summary>
                <span>
                                <p className="pl-9">
                                    MTR is the total revenue in one month. MRR is the revenue divided by the term. Example: A subscription costs €12 and is purchased in January for the duration of 12 months. MRR would be 1€ for the next 12 months (January to December). MTR would be 12€ in January.
                                </p>
                            </span>
              </details>
              <details className="mx-8 my-4">
                <summary
                  className="font-semibold focus:outline-none py-2 px-4 highlight cursor-pointer">
                  What happens when I reach 5.000€ MTR. Will the first 5.000€ remain free?
                </summary>
                <span>
                                <p className="pl-9">
                                    Yes, the first 5.000€ MTR will remain free. For example, if you would increase your MTR to 5.001€, your bill would be 6€.
                                </p>
                            </span>
              </details>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
